import "./App.css";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import Chat from "./Chat";
// import
import useMouse from '@react-hook/mouse-position'


function App() {
  const [username, setUsername] = useState("");
  const [usershort, setUsershort] = useState("");
  const [language, setLanguage] = useState("en-US");
  const room = 'neoanalog';
  const [role, setRole] = useState("guest");
  const [code, setCode] = useState('');
  const [showChat, setShowChat] = useState(false);
  const [appState, setAppState] = useState('login'); // global event state
  const [cursorFPS, setCursorFPS] = useState(30);
  // const [randomTheme, setRandomTheme] = useState(Math.floor(Math.random() * 5));
  const [loginWidth, setLoginWidth] = useState();
  const allowLogin = true;
  // const [allowLogin, setAllowLogin] = useState(false);

  
  const joinRoom = () => {
    if (username !== "" && room !== "" && (allowLogin)) {
      setShowChat(true);
      setAppState('logged-in');
      localStorage.setItem('kp-username', 'Guest');
      localStorage.setItem('kp-code', 'g');
      localStorage.setItem('kp-language', 'en-US');
      localStorage.setItem('kp-autojoin', 'true');
    }

    /* TODO: save */
  };

  const changeUsername = value => {
    
    let name = value;
    if(name.length > 0){
    
    setUsername(name);

    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = [...name.matchAll(rgx)] || [];
    
    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    
    // console.log(initials);

    setUsershort(initials);
    }else{
      setUsername('')
      setUsershort('');
    }
  }

  const ref = useRef(null)
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
    fps: cursorFPS,
  })




  useEffect(() => {
    
    let uname = "Guest";
    let ucode = "g";
    let ulanguage = "en-US";
    // let ucolor = "eins";
    // let uautojoin = 'true';
    
    setUsername(uname);
    changeUsername(uname);
    setCode(ucode);
    setLanguage(ulanguage);

    setShowChat(true);
    setAppState('logged-in');
  
  }, []);

  useLayoutEffect(() => {
    let kpt = document.getElementById('kp-title');
    if(kpt) setLoginWidth(kpt.offsetWidth);
  },[]);

  return (
    <div className={'App '+appState+' r-'+role} ref={ref}>
      {!showChat ? (
        <div>
<h1 id="kp-title">Künstlerinnenpreis NRW 2022</h1>  
<div className="kp-join-panel" style={{"width": loginWidth+'px'}}>
          
          {/* <span className={"avatar " + ((usershort.length > 0) ? 'visible' : null)}>{usershort}</span> */}
          <input
            type="text"
            placeholder="Name"
            value={username}
            onChange={(event) => {
              changeUsername(event.target.value);
            }}
            pattern="[A-Za-z]{3}"
            onKeyPress={(event) => {
              event.key === "Enter" && username.length > 0 && joinRoom();
            }}
          />
          
          
          <button disabled={!allowLogin} onClick={joinRoom}>Enter Event</button>
          { !allowLogin ? (<p>This event is concluded. Come back soon to re-live it.</p>) : ''}
        </div></div>
      ) : (
        <Chat username={username} usershort={usershort} room={room} role={role} code={code} setShowChat={setShowChat} setRole={setRole} appState={appState} setAppState={setAppState} mouse={mouse} language={language} setLanguage={setLanguage} cursorFPS={cursorFPS} setCursorFPS={setCursorFPS}/>
      )}
    </div>
  );
}

export default App;
